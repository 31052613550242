import { useDeskContext } from "../../context/deskContext";
import { useMapEntityContext } from "../../context/mapEntityContext";
import styles from "./DeskCounter.module.css";
type Props = {};

export default function DeskCounter({}: Props) {
  const desks = useDeskContext.use.desks();
  const total = useMapEntityContext.use.totalDeskCount();
  const bookable = desks.filter((d) => d.isBookable).length;
  const occupied = desks.filter((d) => !d.isBookable).length;
  if (total === 0) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <CountEntry count={total}>Total</CountEntry>
      <CountEntry count={total - occupied - bookable}>Available</CountEntry>
      <CountEntry count={bookable}>Bookable</CountEntry>
      <CountEntry count={occupied}>Permanent</CountEntry>
    </div>
  );
}

const CountEntry = ({
  count,
  children,
}: {
  count: number;
  children?: React.ReactNode;
}) => {
  if (count === 0) {
    return <></>;
  }
  return (
    <div className={styles.countEntryContainer}>
      <div className={styles.countEntryCount}>{count}</div>
      <div className={styles.countEntryMeta}>{children}</div>
    </div>
  );
};
